query getEvents($site: [String], $orderBy: String, $limit: Int, $offset: Int, $category: [QueryArgument], $eventType: [QueryArgument], $eventTopic: [QueryArgument], $eventLocation: [QueryArgument], $eventSemester: [QueryArgument], $eventDate: [QueryArgument], $eventEndDate: [QueryArgument], $locale: String) {
	events: entries(site: $site, section: "events", orderBy: $orderBy, limit: $limit, offset: $offset, eventType: $eventType, eventTopic: $eventTopic, eventLocation: $eventLocation, eventSemester: $eventSemester, eventDate: $eventDate, eventEndDate: $eventEndDate, relatedTo: $category) {
		id
		title
		url
		postDate
		...on events_event_Entry {
			eventDate
			eventDateStartTime: eventDate @formatDateTime(format: "H:i")
			eventDateDay: eventDate @formatDateTime (format: "d")
			eventDateDaySuffix: eventDate @formatDateTime (format: "S")
			eventDateWeekday: eventDate @formatDateTime (format: "l", locale: $locale)
			eventDateMonth: eventDate @formatDateTime (format: "F", locale: $locale)
			eventDateYear: eventDate @formatDateTime (format: "Y")
			eventEndDate
			eventDateEndTime: eventEndDate @formatDateTime(format: "H:i")
			eventIsPublic
			eventEntryType
			eventTicketsUrl {
				...on linkField_Link {
					type
					text
					url
					target
				}
			}
			eventLocation {
				id
				title
			}
			eventTopic {
				id
				title
			}
			eventType {
				id
				title
			}
			eventSemester {
				id
				title
			}
			eventLanguage {
				id
				title
			}
			eventSpeakers {
				url
				...on speakers_speaker_Entry {
					speakerTitle
					speakerFirstName
					speakerLastName
					speakerTitleAddition
					speakerSummary
					speakerPhoto {
						id
						kind
						title
						mimeType
						url
						width
						height
						alt
						preview: url @transform(width: 2 height: 2 immediately: false),
						default_1x: url @transform(width: 250 height: 275 immediately: false),
						default_2x: url @transform(width: 500 height: 550 immediately: false),
					}
				}
			}
		}
	}
	total: entryCount(site: $site, section: "events", orderBy: $orderBy, eventType: $eventType, eventTopic: $eventTopic, eventLocation: $eventLocation, eventSemester: $eventSemester, eventDate: $eventDate, eventEndDate: $eventEndDate, relatedTo: $category)
}
