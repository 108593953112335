import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import { createApolloProvider } from '@vue/apollo-option';
// https://stackoverflow.com/a/60750279/2823058

// Name of the localStorage item
const AUTH_TOKEN = import.meta.env.VITE_CRAFT_API_KEY;

// Http endpoint
const HTTP_ENDPOINT = import.meta.env.VITE_CRAFT_GRAPHQL_ENDPOINT;

const cache = new InMemoryCache({ possibleTypes: {
	"AddressInterface": [
		"Address"
	],
	"EntryInterface": [
		"pages_page_Entry",
		"speakers_speaker_Entry",
		"events_event_Entry",
		"journal_article_Entry",
		"publications_publication_Entry"
	],
	"UserInterface": [
		"User"
	],
	"pagesSectionEntryUnion": [
		"pages_page_Entry"
	],
	"ElementInterface": [
		"pages_page_Entry",
		"contentBlocks_BlockRichtext_BlockType",
		"contentBlocks_BlockImage_BlockType",
		"contentBlocks_BlockImageSlider_BlockType",
		"contentBlocks_BlockQuote_BlockType",
		"contentBlocks_BlockAccordion_BlockType",
		"contentBlocks_AtomAccordion_BlockType",
		"contentBlocks_BlockLogoWall_BlockType",
		"contentBlocks_AtomLogo_BlockType",
		"contentBlocks_BlockEventsFilter_BlockType",
		"contentBlocks_BlockJournalTeasers_BlockType",
		"contentBlocks_BlockTextBox_BlockType",
		"contentBlocks_BlockTeaserGrid_BlockType",
		"contentBlocks_AtomTeaser_BlockType",
		"contentBlocks_BlockSpeakersFilter_BlockType",
		"contentBlocks_BlockPublications_BlockType",
		"contentBlocks_BlockSection_BlockType",
		"contentBlocks_BlockGenericTeaser_BlockType",
		"speakers_speaker_Entry",
		"socialLinks_socialLink_BlockType",
		"linkList_linkListItem_BlockType",
		"events_event_Entry",
		"journal_article_Entry",
		"publications_publication_Entry",
		"eventLocations_Category",
		"eventSemesters_Category",
		"eventTopics_Category",
		"eventTypes_Category",
		"globalHeader_GlobalSet",
		"headerNavigation_navigationItem_BlockType",
		"globalFooter_GlobalSet",
		"websiteAssets_Asset",
		"Address",
		"Element",
		"User"
	],
	"contentBlocks_NeoField": [
		"contentBlocks_BlockRichtext_BlockType",
		"contentBlocks_BlockImage_BlockType",
		"contentBlocks_BlockImageSlider_BlockType",
		"contentBlocks_BlockQuote_BlockType",
		"contentBlocks_BlockAccordion_BlockType",
		"contentBlocks_AtomAccordion_BlockType",
		"contentBlocks_BlockLogoWall_BlockType",
		"contentBlocks_AtomLogo_BlockType",
		"contentBlocks_BlockEventsFilter_BlockType",
		"contentBlocks_BlockJournalTeasers_BlockType",
		"contentBlocks_BlockTextBox_BlockType",
		"contentBlocks_BlockTeaserGrid_BlockType",
		"contentBlocks_AtomTeaser_BlockType",
		"contentBlocks_BlockSpeakersFilter_BlockType",
		"contentBlocks_BlockPublications_BlockType",
		"contentBlocks_BlockSection_BlockType",
		"contentBlocks_BlockGenericTeaser_BlockType"
	],
	"NeoBlockInterface": [
		"contentBlocks_BlockRichtext_BlockType",
		"contentBlocks_BlockImage_BlockType",
		"contentBlocks_BlockImageSlider_BlockType",
		"contentBlocks_BlockQuote_BlockType",
		"contentBlocks_BlockAccordion_BlockType",
		"contentBlocks_AtomAccordion_BlockType",
		"contentBlocks_BlockLogoWall_BlockType",
		"contentBlocks_AtomLogo_BlockType",
		"contentBlocks_BlockEventsFilter_BlockType",
		"contentBlocks_BlockJournalTeasers_BlockType",
		"contentBlocks_BlockTextBox_BlockType",
		"contentBlocks_BlockTeaserGrid_BlockType",
		"contentBlocks_AtomTeaser_BlockType",
		"contentBlocks_BlockSpeakersFilter_BlockType",
		"contentBlocks_BlockPublications_BlockType",
		"contentBlocks_BlockSection_BlockType",
		"contentBlocks_BlockGenericTeaser_BlockType",
		"headerNavigation_navigationItem_BlockType"
	],
	"AssetInterface": [
		"websiteAssets_Asset"
	],
	"speakersSectionEntryUnion": [
		"speakers_speaker_Entry"
	],
	"socialLinks_MatrixField": [
		"socialLinks_socialLink_BlockType"
	],
	"MatrixBlockInterface": [
		"socialLinks_socialLink_BlockType",
		"linkList_linkListItem_BlockType"
	],
	"linkList_MatrixField": [
		"linkList_linkListItem_BlockType"
	],
	"eventsSectionEntryUnion": [
		"events_event_Entry"
	],
	"CategoryInterface": [
		"eventLocations_Category",
		"eventSemesters_Category",
		"eventTopics_Category",
		"eventTypes_Category"
	],
	"journalSectionEntryUnion": [
		"journal_article_Entry"
	],
	"publicationsSectionEntryUnion": [
		"publications_publication_Entry"
	],
	"GlobalSetInterface": [
		"globalHeader_GlobalSet",
		"globalFooter_GlobalSet"
	],
	"headerNavigation_NeoField": [
		"headerNavigation_navigationItem_BlockType"
	]
} });

// Config
const defaultOptions = {
	// You can use `https` for secure connection (recommended in production)
	uri: HTTP_ENDPOINT,
	// httpEndpoint: HTTP_ENDPOINT,
	// You can use `wss` for secure connection (recommended in production)
	// Use `null` to disable subscriptions
	// wsEndpoint: process.env.VITE_CRAFT_GRAPHQL_ENDPOINT,
	// LocalStorage token
	tokenName: 'apollo-token',
	// Enable Automatic Query persisting with Apollo Engine
	persisting: false,
	// Use websockets for everything (no HTTP)
	// You need to pass a `wsEndpoint` for this to work
	websocketsOnly: false,
	// Is being rendered on the server?
	ssr: false,

	// Override default apollo link
	// note: don't override httpLink here, specify httpLink options in the
	// httpLinkOptions property of defaultOptions.
	// link: myLink

	// Override default cache
	cache: cache,
	// cache: new InMemoryCache(),

	// Override the way the Authorization header is set
	headers: {
		authorization: 'Bearer ' + AUTH_TOKEN,
	},
};

// Create apollo client
const apolloClient = new ApolloClient({
	...defaultOptions,
});

// Create vue apollo provider
const apolloProvider = createApolloProvider({
	defaultClient: apolloClient,
});

export default apolloProvider;
