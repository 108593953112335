import debounce from 'lodash.debounce';
import { ref, onMounted, onUnmounted } from 'vue';

export default function useIsMobile() {

	const currentMediaQuery = ref(false);
	const isMobile = ref(false);

	function setMediaQuery() {
		const mq = window
			.getComputedStyle(document.body, ':after')
			.getPropertyValue('content')
			.replace(/['"]+/g, '');

		currentMediaQuery.value = mq;

		if (mq !== 'desktop' && mq !== 'tablet') {
			isMobile.value = true;
		} else {
			isMobile.value = false;
		}
		return mq;
	}

	const debounceOnResize = debounce(setMediaQuery, 400);

	onMounted(() => {
		setMediaQuery();

		window.addEventListener('resize', debounceOnResize);
	});

	onUnmounted(() => window.removeEventListener('resize', debounceOnResize));

	return {
		isMobile
	}
}
